import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { IonButtons, IonContent, IonHeader, useIonViewWillEnter, IonAvatar, IonMenuButton, IonPage, IonTitle, IonToolbar, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonImg, IonCardContent, IonRow, IonCol, IonButton, IonText } from '@ionic/react';
import { loadFromLocalStorage } from '../components/LocalStorageHelper';
import Countdown from '../components/Countdown';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWallet, faCalendarAlt, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";

const Booking = () => {

  // Default values that will be overriden by localStorage if it exists
  const [title, setTitle] = useState('Sparkling wine & fun'); // TODO: Rename to eventTitle everywhere
  const [restaurant, setRestaurant] = useState('Lulu'); // TODO: Rename to venue everywhere
  const [city, setCity] = useState('Helsinki')
  const [budget, setBudget] = useState('500');
  const [date, setDate] = useState('2023-10-29'); // TODO: Rename to eventDateAndTime everywhere
  const [seatsAmount, setSeatsAmount] = useState(5); // TODO: Rename to seatsAvailable everywhere
  const [uploadedImage, setUploadedImage] = useState('/temp/sparkling-wine.jpg'); // TODO: Rename to eventImage everywhere
  const [hostPhoto, setHostPhoto] = useState('/temp/influencer.jpg'); // TODO: Implement saving this in profile section
  const [hostName, setHostName] = useState('Leanna'); // TODO: Implement saving this in profile section
  const [eventDescription, setEventDescription] = useState(`
    Join me for a glass of sparkling wine and some fun! Let's play social games and get to know each other in person!
  `);

  // Load data from localStorage on initial render.
  useIonViewWillEnter(() => {
    const inviteData = loadFromLocalStorage('inviteData');
    console.log(inviteData)

    inviteData?.title && setTitle(inviteData.title);
    inviteData?.restaurant && setRestaurant(inviteData?.restaurant);
    inviteData?.city && setRestaurant(inviteData?.city);
    inviteData?.budget && setBudget(inviteData.budget);
    inviteData?.date && setDate(inviteData.date);
    inviteData?.seatsAmount && setSeatsAmount(inviteData.seatsAmount);
    inviteData?.uploadedImage && setUploadedImage(inviteData.uploadedImage);
    inviteData?.eventDescription && setEventDescription(inviteData.eventDescription);

    console.log('restaurant', restaurant)

  });

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Inner Circle Fans</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">

        <IonCard>
          <IonCardHeader>

            <div style={{ display: 'flex', alignItems: 'center' }}>
              <IonAvatar style={{ marginRight: '20px' }}>
                <img src={hostPhoto} alt={title} />
              </IonAvatar>

              <IonCardTitle>
                {title} <span style={{ opacity: '.5' }}>hosted by</span> Leanna
              </IonCardTitle>
            </div>

            <IonText style={{ marginTop: '2em' }}>{eventDescription}</IonText>

            <IonCardSubtitle>Seats left 1/{seatsAmount}</IonCardSubtitle>
            <br />

          </IonCardHeader>

          <IonImg src={uploadedImage} alt={title} />

          <IonCardContent>
            <IonRow>
              <IonCol>
                <FontAwesomeIcon icon={faWallet} />&nbsp;&nbsp;
                {budget} €
              </IonCol>
              <IonCol>
                <FontAwesomeIcon icon={faCalendarAlt} />&nbsp;&nbsp;
                {date}
              </IonCol>
              <IonCol>
                <FontAwesomeIcon icon={faMapMarkerAlt} />&nbsp;&nbsp;
                {city}
              </IonCol>
            </IonRow>
            <br />
            <IonCardSubtitle>Sales closes in <Countdown initialHours={4} initialMinutes={32} initialSeconds={15} /></IonCardSubtitle>
          </IonCardContent>

          <br />

          <IonButton expand="full" onClick={() => window.location.href = 'https://book.stripe.com/6oE2ap7WO2Fl728288'} >Grab your seat!</IonButton>
        </IonCard>


      </IonContent>
    </IonPage >
  );
};

export default Booking;

// TODO:
// Refactor view names