import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonImg, IonCardContent, IonRow, IonCol, IonButton, IonText } from '@ionic/react';

const MeetingDetails = () => {

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>Inner Circle Fans</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">

                <IonCard>
                    <IonCardHeader>

                        <IonCardTitle>Dinner hosted by Leanna</IonCardTitle>

                        <IonCardSubtitle>Date and time: 29th Oct 2023 at 20.00–22.00</IonCardSubtitle>
                        {/* This data from localStorage */}

                        <IonCardSubtitle>Restaurant: Lulu, Helsinki</IonCardSubtitle>
                        {/* This data from localStorage */}

                        <IonCardSubtitle>More information & details: manager Jack Jofa</IonCardSubtitle><br />

                        <IonCardTitle>See you there! 👄</IonCardTitle>

                    </IonCardHeader>
                </IonCard>

            </IonContent>
        </IonPage>
    );
};

export default MeetingDetails;
