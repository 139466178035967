import React from 'react';
import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
} from '@ionic/react';

import { useLocation } from 'react-router-dom';
import { archiveOutline, archiveSharp, heartOutline, heartSharp, trashOutline, refreshOutline } from 'ionicons/icons';
import './Menu.css';

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}

const appPages: AppPage[] = [
  {
    title: 'Invitation',
    url: '/page/Booking',
    iosIcon: heartOutline,
    mdIcon: heartSharp
  },
  {
    title: 'Create invitation',
    url: '/page/Invite',
    iosIcon: archiveOutline,
    mdIcon: archiveSharp
  },
];

const Menu: React.FC = () => {
  const location = useLocation();

  const resetAppData = () => {
    localStorage.clear();
    window.location.reload();
  }

  const refreshApp = () => {
    window.location.reload();
  }

  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <IonList id="inbox-list">
          <IonListHeader>Menu</IonListHeader>
          <IonNote>Jack Jofa</IonNote>
          {appPages.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem className={location.pathname === appPage.url ? 'selected' : ''} routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>
                  <IonIcon aria-hidden="true" slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}

          <IonMenuToggle autoHide={false}>
            <IonItem onClick={refreshApp} routerLink='#' routerDirection="none" lines="none">
              <IonIcon aria-hidden="true" slot="start" ios={refreshOutline} md={refreshOutline} />
              <IonLabel>Refresh App</IonLabel>
            </IonItem>
          </IonMenuToggle>

          <IonMenuToggle autoHide={false}>
            <IonItem onClick={resetAppData} routerLink='#' routerDirection="none" lines="none">
              <IonIcon aria-hidden="true" slot="start" ios={trashOutline} md={trashOutline} />
              <IonLabel>Reset App Data</IonLabel>
            </IonItem>
          </IonMenuToggle>
        </IonList>

        <div style={{ position: 'absolute', bottom: '10px', width: '100%', textAlign: 'left', opacity: '.5' }}>
          <small>v0.1.5</small>
        </div>

      </IonContent>
    </IonMenu>
  );
};

export default Menu;
