import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { IonButtons, IonContent, IonToast, IonTextarea, IonRange, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonImg, IonCardContent, IonRow, IonCol, IonButton, IonInput, IonDatetime } from '@ionic/react';
import { saveToLocalStorage, loadFromLocalStorage } from '../components/LocalStorageHelper';

const Invite = () => {

    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');

    const fileInputElement = useRef(null);

    const handleImageUpload = (file) => {
        const reader = new FileReader();

        reader.onloadend = () => {
            setUploadedImage(reader.result);
        }

        reader.readAsDataURL(file);
    }

    const onDrop = (event) => {
        event.preventDefault();

        if (event.dataTransfer.items) {
            for (var i = 0; i < event.dataTransfer.items.length; i++) {
                if (event.dataTransfer.items[i].kind === 'file') {
                    var file = event.dataTransfer.items[i].getAsFile();
                    handleImageUpload(file);
                }
            }
        }
    }

    const onDragOver = (event) => {
        event.preventDefault();
    }

    const onUploadClick = () => {
        fileInputElement.current.click();
    }

    const onFileSelected = event => {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            handleImageUpload(file);
        }
    }

    const [title, setTitle] = useState('');
    const [restaurant, setRestaurant] = useState('');
    const [city, setCity] = useState('');
    const [budget, setBudget] = useState('');
    const [date, setDate] = useState('');
    const [seatsAmount, setSeatsAmount] = useState(5);
    const [uploadedImage, setUploadedImage] = useState('');
    const [eventDescription, setEventDescription] = useState('');

    const [currentDateAndTime, setCurrentDateAndTime] = useState(new Date().toISOString());

    const handleSave = () => {
        const inviteData = {
            title,
            restaurant,
            city,
            budget,
            date,
            seatsAmount,
            uploadedImage,
            eventDescription
        };
        saveToLocalStorage('inviteData', inviteData);
        setToastMessage('Event details saved');
        setShowToast(true);
    }

    useEffect(() => {
        const savedInviteData = loadFromLocalStorage('inviteData');
        if (savedInviteData) {
            const { title, restaurant, city, budget, date, seatsAmount, uploadedImage, eventDescription } = savedInviteData;
            setTitle(title);
            setRestaurant(restaurant);
            setCity(city);
            setBudget(budget);
            setDate(date);
            setSeatsAmount(seatsAmount);
            setUploadedImage(uploadedImage);
            setEventDescription(eventDescription);
        }

    }, []);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>Inner Circle Fans</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">

                <IonCard>
                    <IonCardHeader>
                        <IonCardTitle>Create Event</IonCardTitle>
                    </IonCardHeader>

                    <IonCardContent>

                        <IonInput
                            labelPlacement="floating" label="Title or theme" fill="outline" value={title} onIonChange={e => setTitle(e.target.value)}></IonInput><br />

                        <IonTextarea value={eventDescription} labelPlacement="floating" label="Event description" fill="outline" style={{ height: '7em' }} onIonChange={e => setEventDescription(e.target.value)}></IonTextarea><br />

                        <input type='file' ref={fileInputElement} style={{ display: 'none' }} onChange={onFileSelected} />

                        {uploadedImage ?
                            <IonImg src={uploadedImage} alt="Uploaded" onDrop={onDrop} onDragOver={onDragOver} onClick={onUploadClick} style={{ cursor: 'pointer' }} />
                            :
                            <div onDrop={onDrop} onDragOver={onDragOver} onClick={onUploadClick} style={{ width: 200, height: 200, border: '1px solid rgba(255,255,255,0.2', borderRadius: '5px', padding: '1em', cursor: 'pointer' }}>
                                Drag 'n drop event photo or click to upload
                            </div>
                        }
                        <br />

                        <IonDatetime displayFormat="MM DD YY HH:mm" placeholder="Select date & time" value={currentDateAndTime} onIonChange={e => setDate(e.target.value)}></IonDatetime><br /><br />

                        <IonInput labelPlacement="floating" label="Restaurant name" fill="outline" value={restaurant} onIonChange={e => setRestaurant(e.target.value)}></IonInput><br />

                        <IonInput labelPlacement="floating" label="Budget per person" fill="outline" value={budget} inputMode='numeric' onIonChange={e => setBudget(e.target.value)}></IonInput><br />

                        <IonRange
                            labelPlacement="start"
                            label={`Seats available: ${seatsAmount}`}
                            ticks={true}
                            snaps={true}
                            min={1}
                            max={10}
                            value={seatsAmount}
                            onIonChange={e => setSeatsAmount(e.target.value)}
                        />

                        <IonButton onClick={handleSave}>Save</IonButton>

                    </IonCardContent>

                </IonCard>

            </IonContent>

            <IonToast
                isOpen={showToast}
                onDidDismiss={() => setShowToast(false)}
                message={toastMessage}
                duration={2000}
                position="middle"
                animated={true}
                translucent={true}
            />

        </IonPage>
    );
};

export default Invite;
